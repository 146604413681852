<template lang="html">
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo" />
            <div class="profile wrapper">
                <div class="profile-header-block">
                    <div class="profile-header-block__body">
                        <div class="profile-container">
                            <div class="profile-title">
                                &nbsp;
                            </div>
                            <div class="profile-content">
                                <!-- avatar -->
                                <avatar-loader
                                    :avatar-fallback="getterPersonalInformation.avatar_fallback"
                                    :avatar-url="getterPersonalInformation.avatar_url"
                                />
                                <!-- avatar -->
                                <div class="profile-content__block">
                                    <!-- account name -->
                                    <div class="profile-header-block__inner-row">
                                        <div class="profile-header-block__name">
                                            <p class="profile-header-block--label">
                                                Account name:
                                            </p>
                                            <p class="profile-header-block--value profile-header-block--value_username">
                                                {{ getterPersonalInformation.firstname }} {{ getterPersonalInformation.lastname }}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- account name -->
                                    <!-- Nickname -->
                                    <div class="profile-header-block__inner-row">
                                        <div>
                                            <p class="profile-header-block--label">
                                                Nickname / screen-name:
                                            </p>
                                            <p class="profile-header-block--value">
                                                {{ getterPersonalInformation.nickname }}
                                            </p>
                                        </div>
                                        <custom-button
                                            small
                                            hover
                                            :classes="'btn-pencil'"
                                            :width="'50px'"
                                            :height="'28px'"
                                            @on-btn-click="$router.push({ name: 'profile_nickname' })"
                                        >
                                            <pencil />
                                            Edit
                                        </custom-button>
                                    </div>
                                    <!-- Nickname -->
                                </div>
                            </div>
                        </div>
                        <div class="profile-container">
                            <div class="profile-title">
                                Profile Details
                            </div>
                            <div class="profile-content">
                                <div class="profile-content__block">
                                    <!-- general info -->
                                    <div class="profile-header-block__inner-row">
                                        <BubbleAttention
                                            v-if="getterNeedUpdateGeneralInfo"
                                            message="Please update your general information"
                                        />
                                        <div>
                                            <router-link
                                                class="profile-header-block--link"
                                                style="white-space: nowrap"
                                                :to="{ name: 'profile_writers' }"
                                            >
                                                General information
                                                <open-in-new />
                                            </router-link>
                                        </div>
                                    </div>
                                <!-- general info -->
                                </div>
                                <div class="profile-content__block">
                                    <!-- withdrawal settings -->
                                    <div class="profile-header-block__inner-row">
                                        <div>
                                            <router-link
                                                class="profile-header-block--link"
                                                style="white-space: nowrap"
                                                :to="{ name: 'profile_documents' }"
                                            >
                                                Files
                                                <open-in-new />
                                            </router-link>
                                        </div>
                                    </div>
                                    <!-- withdrawal settings -->
                                </div>
                            </div>
                        </div>
                        <div class="profile-container">
                            <div class="profile-title">
                                Contact Details
                            </div>
                            <div class="profile-content">
                                <div class="profile-content__block">
                                    <!-- email -->
                                    <div class="profile-header-block__inner-row">
                                        <div>
                                            <p class="profile-header-block--label">
                                                Email:
                                            </p>
                                            <p class="profile-header-block--value">
                                                {{ getterPersonalInformation.email }}
                                            </p>
                                        </div>
                                        <custom-button
                                            small
                                            hover
                                            :classes="'btn-pencil'"
                                            :width="'50px'"
                                            :height="'28px'"
                                            @on-btn-click="$router.push({ name: 'profile_email' })"
                                        >
                                            <pencil />
                                            Edit
                                        </custom-button>
                                    </div>
                                    <!-- email -->
                                </div>
                                <div class="profile-content__block">
                                    <!-- phone -->
                                    <div
                                        class="profile-header-block__inner-row"
                                    >
                                        <div>
                                            <p
                                                class="profile-header-block--label"
                                            >
                                                <BubbleAttention
                                                    v-if="getterNeedUpdatePhone"
                                                    message="Please update your phone number"
                                                />
                                                Phone:
                                            </p>
                                            <p class="profile-header-block--value">
                                                <span v-if="getterWriterPhoneData.phone">
                                                    {{ getterWriterPhoneData.phone_code || '+' }}
                                                    {{ getterWriterPhoneData.phone }}
                                                </span>
                                                <span v-else>
                                                    Not provided
                                                </span>
                                            </p>
                                        </div>
                                        <custom-button
                                            small
                                            hover
                                            :classes="'btn-pencil'"
                                            :width="'50px'"
                                            :height="'28px'"
                                            @on-btn-click="$router.push({ name: 'profile_phone' })"
                                        >
                                            <pencil />
                                            Edit
                                        </custom-button>
                                    </div>
                                    <!-- phone -->
                                </div>
                                <div class="profile-content__block">
                                    <!-- address -->
                                    <div class="profile-header-block__inner-row">
                                        <div>
                                            <p class="profile-header-block--label">
                                                Address:
                                            </p>
                                            <p class="profile-header-block--value">
                                                <span v-if="getAddressDataFields.address && getAddressDataFields.city && getAddressDataFields.zip && getAddressDataFields.country">
                                                    {{ getAddressDataFields.address }}, {{ getAddressDataFields.city }}, {{ getAddressDataFields.zip }}, {{ getAddressDataFields.country }}
                                                </span>
                                                <span v-else>
                                                    Not provided
                                                </span>
                                            </p>
                                        </div>
                                        <custom-button
                                            small
                                            hover
                                            :classes="'btn-pencil'"
                                            :width="'50px'"
                                            :height="'28px'"
                                            @on-btn-click="$router.push({ name: 'profile_address' })"
                                        >
                                            <pencil />
                                            Edit
                                        </custom-button>
                                    </div>
                                    <!-- address -->
                                </div>
                            </div>
                        </div>
                        <div class="profile-container">
                            <div class="profile-title">
                                Security
                            </div>
                            <div class="profile-content">
                                <div class="profile-content__block">
                                    <!-- password -->
                                    <div class="profile-header-block__inner-row">
                                        <div>
                                            <p class="profile-header-block--label">
                                                Password:
                                            </p>
                                            <p class="profile-header-block--value">
                                                **********
                                            </p>
                                        </div>
                                        <custom-button
                                            small
                                            hover
                                            :classes="'btn-pencil'"
                                            :width="'50px'"
                                            :height="'28px'"
                                            @on-btn-click="$router.push({ name: 'profile_password' })"
                                        >
                                            <pencil />
                                            Edit
                                        </custom-button>
                                    </div>
                                    <!-- password -->
                                </div>
                                <div class="profile-content__block">
                                    <!-- 2FA -->
                                    <div class="profile-header-block__inner-row">
                                        <div>
                                            <p class="profile-header-block--label">
                                                Two-Factor Authentication:
                                            </p>
                                            <p class="profile-header-block--value">
                                                {{ getter2FA ? 'Enabled' : 'Disabled' }}
                                            </p>
                                        </div>
                                        <custom-button
                                            small
                                            hover
                                            :classes="'btn-pencil'"
                                            :width="'50px'"
                                            :height="'28px'"
                                            @on-btn-click="$router.push({ name: 'profile_security' })"
                                        >
                                            <pencil />
                                            Edit
                                        </custom-button>
                                    </div>
                                    <!-- 2FA -->
                                </div>
                            </div>
                        </div>
                        <div class="profile-container">
                            <div class="profile-title">
                                Payout Settings
                            </div>
                            <div class="profile-content">
                                <div class="profile-content__block">
                                    <!-- withdrawal settings -->
                                    <div class="profile-header-block__inner-row">
                                        <div>
                                            <router-link
                                                class="profile-header-block--link"
                                                style="white-space: nowrap"
                                                :to="{ name: 'profile_payment-method' }"
                                            >
                                                Withdrawal method
                                                <open-in-new />
                                            </router-link>
                                        </div>
                                    </div>
                                    <!-- withdrawal settings -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </main-layout>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import MainLayout from '@/layouts/MainLayout';
import Pencil from 'mdi-vue/Pencil'
import OpenInNew from 'mdi-vue/OpenInNew'
import HeaderContent from '@/components/HeaderContent';
import AvatarLoader from '@/components/AvatarLoader';

import { createNamespacedHelpers, mapGetters } from 'vuex';
import {
    ACTION_WRITER_UPDATE_SETTINGS
} from '@/store/modules/writer/action-types';
import BubbleAttention from '../../components/BubbleAttention.vue';

const {
    mapActions: mapWriterActions
} = createNamespacedHelpers('writer');

export default {
    components: {
        MainLayout,
        Pencil,
        OpenInNew,
        HeaderContent,
        AvatarLoader,
        BubbleAttention
    },
    metaInfo: {
        title: 'Settings'
    },
    data() {
        return {
            headerInfo: {
                title: 'Account Settings',
                className: 'header-content__account-setting',
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    }
                ]
            }
        }
    },
    computed: {
        ...mapGetters('writer', [
            'getterPersonalInformation',
            'getAddressDataFields',
            'getterWriterPhoneData',
            'getterWriterStatistics',
            'getterPaymentsInfo',
            'getter2FA',
            'getterNeedUpdatePhone',
            'getterNeedUpdateGeneralInfo'
        ])
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    methods: {
        ...mapWriterActions({
            updateSettings: ACTION_WRITER_UPDATE_SETTINGS
        }),
        submit() {
            try {
                const response = this.updateSettings({
                    chk_agree: 1,
                    chk_email_notifications: 1,
                    chk_email_marketing: 1,
                    chk_calls: 1
                })
                if (response) {
                    eventBus.$emit('showSnackBar', 'Your settings have been saved', 'success');
                }
            } catch (err) {
                eventBus.$emit('showSnackBar', 'Failed to update settings', 'error');
            }
        }
    }
    // beforeRouteEnter(to, from, next) {
    //     next(async (vm) => {
    //         await vm.updateClient()
    //     })
    // }
}
</script>

<style lang="scss">
.account-settings_checkboxes {
    .checkox-block {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        &:first-child {
            margin-top: 20px;
        }
    }
}

.btn-base.small-btn.btn-pencil {
    border: none;
    font-size: 14px;
    &:hover {
        color: $main-color;
        svg {
            color: $main-color;
        }
    }
    svg {
        margin-right: 10px;
        color: $font-color-main;
        transition: .2s;
    }
}

.v-diver {
    display: block;
    flex: 1 1 0px;
    max-width: 100%;
    height: 0;
    max-height: 0;
    border: solid;
    border-width: thin 0 0 0;
    transition: inherit;
    border-color: rgba(0,0,0,.12);
}
.profile-header-block--value.ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
    max-width: 200px;
}
.phonetic {
    margin-right: 4px;
}
.profile-header-block {
    &__document{
        border-top: 1px solid #dedede;
        padding: 40px 0 0;
        @media (max-width: 480px) {
            padding: 20px 0 0;
        }
    }
    &__name {
        width: 100%;
    }
}
</style>
